import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const DomainManagerPage = () => (
  <div>
    <SEO 
        title="Domain Names Manager" 
        description="Multiple domain name supervision directly from your cloud hosting account."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title"><span className="block text-indigo-600 xl:inline">Domain Names  </span> Manager <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">Multiple domain name supervision directly from your cloud hosting account.</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/domains/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/domain-manager-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> The does–it–all Domain Names Manager interface, included inside the Web Control Panel 
                 can certainly do everything. It enables you to deal with multiple domain names conveniently, through the same spot you control 
                 your web sites and billing. Also it enables you to quickly switch from one to the other without dropping any modifications.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/multiple-domains-management.webp" className="w-60" alt="Numerous Domain" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Numerous Domain</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Alter Whois, name servers, custom DNS<br/><br/>
                                    No matter how many domain names you’ve got, you can easily command them with a click of the mouse through 
                                    the Sheephostingbay Domain Names Manager! You’re able to manage the WHOIS data, modify the name servers 
                                    and set unique DNS records to as many domain names as you wish straight from your Web Control Panel.
                                    <br/><br/>
                                    By employing the Domain Names Manager, you will get direct access to the files of your domains and will 
                                    be allowed to revise the bundled e–mail accounts on top of that. Furthermore, you’ll be able to monitor 
                                    the web statistics for each and every one of your domain names. The bulk selection will allow you to 
                                    register as well as renew a lot of domains at a time.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">DNS Records</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Manage all DNS records<br/><br/>
                                    In the Web Control Panel, you can see a method to create many DNS records for your personal domain names. 
                                    You’ll be able to point your domain names to external web servers via A or AAAA records, implement 3rd 
                                    party mail servers by means of MX records, establish domain name redirection along with CNAME records, 
                                    allow support for additional services utilizing TXT records, etc. You’ll be able to modify the 
                                    configured DNS records and also return them to their defaulting values with just a mouse 
                                    click.
                                    <br/><br/>
                                    Further than the customizable DNS records, also you can register name servers that can be dependant on your 
                                    domain. Therefore every one of your customers will discover your brand, as opposed to our universal name 
                                    servers, every time they make a WHOIS search.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/dns-records-management.webp" className="w-60" alt="DNS Records" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/domain-name-parking.webp" className="w-60" alt="Domain Parking" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Domain Parking</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Park your domains in as little as one step<br/><br/>
                                    Domain Parking is an excellent service that can be used to get engaging domains and place them on the internet 
                                    and never have a website. You can actually park as much domains as you want from the Domain Names Manager 
                                    within your Web Control Panel. Parking a domain can make it present an ’Under Construction’ webpage, a 
                                    "for sale" web page, and even point the domain to an alternative web site.
                                    <br/><br/>
                                    And when you’ve got your web site prepared, you’ll be able to host back your domain name with simply one single 
                                    click! Your newly published web site will get on the web in an instant.
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Domain Redirection</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Incorporated domain name redirection<br/><br/>
                                    In order to redirect your domain to another domain, you can do that along with a click from your Domain Names 
                                    Manager. We supply a simple to use tool for domain redirection. All that you should do is type in the web 
                                    address in which you wish to redirect your domain to. You can point it to a different domain name within 
                                    your web hosting account or even to every other domain name on the web.
                                    <br/><br/>
                                    And in case you need to point the domain name straight back to your website hosting account – simply click on 
                                    the ’Host Domain’ button and our system shall execute all the rest.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/domain-redirection.webp" className="w-60" alt="Domain Redirection" />
                            </div>
                        </div>  

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/whois-protection.webp" className="w-60" alt="Whois Privacy Protection" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Whois Privacy Protection</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Secure your data from exploitation<br/><br/>
                                    Each individual domain name you register incorporates your private information which are normally visible by the 
                                    community. As there is an opportunity that somebody takes advantage of your details for spamming purposes, you 
                                    could have a look at a WHOIS Privacy Protection service. It is going to conceal all private data from the 
                                    general public, to make sure that if somebody performs a WHOIS check, they are going to simply discover 
                                    common data and not yourpersonal information.
                                    <br/><br/>
                                    However , don’t be concerned, when someone has got to communicate with you about your domain – all communication 
                                    demands will still be shipped to your e–mail address.
                                    </div>
                                </div>
                            </div>
                        </div>  

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">SSL Certificates</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    A must have for each and every online store<br/><br/>
                                    Should you have a internet store that allows online payments or you possess a customer sign in area for your 
                                    website, you should think of putting in an SSL Certificate on it. It’s a quick method to secure the link in 
                                    between your web site as well as your website visitors, so that the info carried such as charge card 
                                    information and sign in details is protected. In the Domain Names Manager, it’s easy to get a 
                                    whole new SSL certificate for your site.
                                    <br/><br/>
                                    An SSL certificate may be used exclusively for one website. If you require an SSL certificate for a wide range 
                                    of websites, you could take a look at a Wildcard SSL certificate.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/ssl-certificates.webp" className="w-60" alt="SSL Certificates" />
                            </div>
                        </div>   
                      
                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default DomainManagerPage
